import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

function Iteams({ item }) {
    const [Open, SetOpen] = useState(false);

    if (item.childrens) {
        return (
            <>
                <div className={Open ? "nav-item menu-open" : "nav-item"}>
                    <Link to className="nav-link" onClick={() => SetOpen(!Open)} >

                        {item.icon && <i className={item.icon}></i>}
                        <p>
                            {item.title}
                            <i className="right fas fa-angle-left" />
                        </p>
                    </Link>
                    <div className="nav nav-treeview">
                        <div className="nav-item">
                            {item.childrens.map((child, index) => <Iteams key={index} item={child} />)}
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <li className="nav-item">
                    <Link to={item.path} className="nav-link">
                        {item.icon && <i className={item.icon}></i>}
                        <p>
                            {item.title}
                        </p>
                    </Link>
                </li>
            </>
        );
    }
}

export default Iteams;