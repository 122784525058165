import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";

function Y_Edit() {
  const { id } = useParams();
  const redirect = useNavigate();
  const [cookies] = useCookies(["jwt"]);
  const Message = (M) => toast.success(M, { autoClose: 1000 });
  const [Disabled, Setdisabled] = useState(0);
  const [Data, SetCategory] = useState({
    year: "",
    date: "",
    _id: "",
  });

  const OnInputs = (e) => {
    SetCategory({ ...Data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    axios
      .get(`https://darkweb4k.vercel.app/api/admin/year/${id}`, {
        headers: cookies,
      })
      .then(function (response) {
        SetCategory(response.data.Data);
      })
      .catch(function (error) {});
  }, []);

  const Submited = (e) => {
    e.preventDefault();

    axios
      .put("https://darkweb4k.vercel.app/api/admin/year", {
        Headers: cookies,
        data: Data,
      })
      .then(function (response) {
        Setdisabled(0);
        Message(response.data.updated);
        redirect("/admin/Year/index");
      })
      .catch(function (error) {
        console.log(error);
        Setdisabled(0);
      });
    Setdisabled(1);
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header"></div>
        <section className="content pb-3">
          <div className="container-fluid">
            {/* /.card */}
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="m-0">Year</h3>
                <Link
                  to="/admin/Year/index"
                  className="btn btn-success ml-auto"
                >
                  View Year
                </Link>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <form onSubmit={(e) => Submited(e)}>
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">Year</label>
                      <input
                        type="text"
                        name="year"
                        value={Data.year}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">Date</label>
                      <input
                        type="date"
                        name="date"
                        value={Data.date}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                      <input
                        type="hidden"
                        name="_id"
                        value={Data._id}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>
                    <div className="mx-auto mt-3">
                      <button className="btn btn-success" disabled={Disabled}>
                        Update
                      </button>
                    </div>
                  </div>
                  {/* <div className="form-group">
                                        <label htmlFor="exampleInputFile">File input</label>
                                        <div className="input-group">
                                            <div className="custom-file">
                                                <input type="file" className="custom-file-input" id="exampleInputFile" />
                                                <label className="custom-file-label" htmlFor="exampleInputFile">Choose file</label>
                                            </div>
                                            <div className="input-group-append">
                                                <span className="input-group-text">Upload</span>
                                            </div>
                                        </div>
                                    </div> */}
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}

export default Y_Edit;
