import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function CardSkeletonSingle() {
  return (
    <>
      <section className="movie-detail" style={{ marginTop: "-60px" }}>
        <div className="container">
          <figure className="movie-detail-banner" style={{ width: "100%" }}>
            <span>
              <Skeleton height={405} width={"100%"} />
            </span>
            <button className="play-btn2">
              <ion-icon name="play-circle-outline" />
            </button>
          </figure>
          <div className="movie-detail-content" style={{ width: "100%" }}>
            <h3 className="detail-title">
              <strong>
                <Skeleton count={3}></Skeleton>
              </strong>
            </h3>
            <div className="storyline" style={{ width: "100%" }}>
              <Skeleton count={10}></Skeleton>
            </div>
            <div style={{ width: "100%" }}>
              <Skeleton height={120} width={"100%"}></Skeleton>
            </div>
          </div>
        </div>
        <div className="container mt-3 justify-content-center">
          <div className="justify-content-center" style={{ width: "100%" }}>
            <span>
              <Skeleton height={200} width={"100%"} count={3} />
            </span>
          </div>
        </div>
        <div className="text-center mt-4" style={{ width: "100%" }}>
          <p className="text-info">{<Skeleton height={50} width={"100%"} />}</p>
          <p className="text-success bg-black p-3">
            {<Skeleton height={50} width={"100"} />}
          </p>
        </div>
      </section>
    </>
  );
}

export default CardSkeletonSingle;
