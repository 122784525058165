import React from "react";
import Footer from "./Layouts/Footer";
import Header from "./Layouts/Header";

function Proteced(props) {
  const Comps = props.Component;
  return (
    <>
      <Header></Header>
      <Comps />
      <Footer></Footer>
    </>
  );
}

export default Proteced;
