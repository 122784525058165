import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";

function Y_Index() {
  const [cookies] = useCookies(["jwt"]);
  const [Load, SetLoad] = useState([]);
  const Message = (M) => toast.error(M, { autoClose: 1000 });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    axios
      .get("https://darkweb4k.vercel.app/api/admin/year", {
        headers: cookies,
      })
      .then(function (response) {
        SetLoad(response.data);
      })
      .catch(function (error) {});
  }, []);

  const Deleted = (id) => {
    axios
      .delete(`https://darkweb4k.vercel.app/api/admin/year/${id}`, {
        headers: cookies,
      })
      .then(function (response) {
        axios
          .get("https://darkweb4k.vercel.app/api/admin/year", {
            headers: cookies,
          })
          .then(function (response) {
            SetLoad(response.data);
          })
          .catch(function (error) {});
        Message(response.data.message);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="content-wrapper">
        <div className="content-header"></div>
        <section className="content pb-3">
          <div className="container-fluid">
            {/* /.card */}
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="m-0">Year</h3>
                <Link
                  to="/admin/Year/create"
                  className="btn btn-success ml-auto"
                >
                  <i className="nav-icon far fa-plus-square p-2 text-primary"></i>
                  Year
                </Link>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped text-center">
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>Year</th>
                        <th>Date</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Load.map((iteam, index) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{iteam.year}</td>
                          <td>{iteam.date}</td>
                          <td>
                            <div className="d-flex">
                              <Link
                                to={`/admin/Year/edit/${iteam._id}`}
                                className="btn btn-success btn-sm m-1"
                              >
                                <i className="nav-icon fas fa-edit" />
                              </Link>
                              <Link
                                to
                                className="btn btn-danger btn-sm m-1"
                                onClick={() => Deleted(iteam._id)}
                              >
                                <i className="nav-icon fas fa-trash" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}

export default Y_Index;
