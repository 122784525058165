import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="footer2 bg-black">
        <div className="footer2-top">
          <div className="container">
            <div className="divider2" />
            <div className="quicklink2-wrapper" style={{ marginTop: "-100px" }}>
              <ul className="quicklink2-list">
                <li>
                  <Link to="#" className="quicklink2-link">
                    Faq
                  </Link>
                </li>
                <li>
                  <Link to="#" className="quicklink2-link">
                    Help center
                  </Link>
                </li>
                <li>
                  <Link to="/disclaimer" className="quicklink2-link">
                    Disclaimer
                  </Link>
                </li>
                <li>
                  <Link to="/privacy" className="quicklink2-link">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
              <ul className="quicklink2-list">
                <li>
                  <Link to="/" className="quicklink2-link">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/" className="quicklink2-link">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/" className="quicklink2-link">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <ul className="social-list">
                <li>
                  <Link to="/" className="social-link" target="_blank">
                    <ion-icon name="logo-facebook" />
                  </Link>
                </li>
                <li>
                  <a
                    href="https://instagram.com/bagimovies?igshid=YmMyMTA2M2Y="
                    className="social-link"
                    target="_blank"
                  >
                    <ion-icon name="logo-instagram" />
                  </a>
                </li>
                <li>
                  <Link to="#" className="social-link">
                    <ion-icon name="logo-pinterest" />
                  </Link>
                </li>
                <li>
                  <a
                    href="https://telegram.me/movies4k_vercel_app"
                    target="_blank"
                    className="social-link"
                  >
                    {/* <ion-icon name="logo-linkedin" /> */}
                    <ion-icon
                      name="navigate-circle-outline"
                      style={{ fontSize: "22px" }}
                    ></ion-icon>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container p-3 bg-black">
          <p className="copyright pt-lg-3">
            © 2022 <Link to="/admin">code with Movies4k</Link>. All Rights
            Reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
