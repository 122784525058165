import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination } from "swiper";


function Cardsub() {
  return (
    <>
      <Swiper
        slidesPerView={2}
        spaceBetween={10}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 10,
          },

        }
        }
        pagination={true} modules={[Pagination]} className="mySwiper" navigation
      >
        {
          [1, 2, 3, 4, 5].map((item, index) => (
              <SwiperSlide key={index + 1}>
                <Skeleton height={400} width={"100%"} />
              </SwiperSlide>
          ))
        }


      </Swiper>


    </>
  );
}

export default Cardsub;
