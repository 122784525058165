import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import "./Singl_page.css";

const Single_Page = () => {
  const { id } = useParams();
  const [Linkes, SetLink] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    axios
      .get(`https://darkweb4k.vercel.app/api/websites/${id}`)
      .then(function (response) {
        // console.log(response.data.show1);
        SetLink(response.data.show1);
        // other movies
      })
      .catch(function (error) {});
  }, []);

  return (
    <section className="">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h1 className="text-info">Download Links</h1>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col text-center">
            <h4 className="bg-red py-3">
              {Linkes.taitle} <br /> Download Now
            </h4>
            <div className="text-center m-3">
              <div>
                {Linkes.link480p || Linkes.link480p2 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h3>480P</h3>
                    </div>
                    <div className="vn-green" style={{ textAlign: "center" }}>
                      {Linkes.link480p ? (
                        <a
                          href={Linkes.link480p}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 1
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-red" />
                        </a>
                      )}
                      {Linkes.link480p2 ? (
                        <a
                          href={Linkes.link480p2}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 2
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-red" />
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {Linkes.link720p || Linkes.link720p2 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h3>720P</h3>
                    </div>
                    <div className="vn-red" style={{ textAlign: "center" }}>
                      {Linkes.link720p ? (
                        <a
                          href={Linkes.link720p}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 1
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-info" />
                        </a>
                      )}
                      {Linkes.link720p2 ? (
                        <a
                          href={Linkes.link720p2}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 2
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-info" />
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {Linkes.link1080p || Linkes.link1080p2 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h3>1080P</h3>
                    </div>
                    <div
                      className="vn-wisteria"
                      style={{ textAlign: "center" }}
                    >
                      {Linkes.link1080p ? (
                        <a
                          href={Linkes.link1080p}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 1
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-red" />
                        </a>
                      )}
                      {Linkes.link1080p2 ? (
                        <a
                          href={Linkes.link1080p2}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 2
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-red" />
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {Linkes.link2k || Linkes.link2k2 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h3>2K</h3>
                    </div>
                    <div className="vn-orange" style={{ textAlign: "center" }}>
                      {Linkes.link2k ? (
                        <a
                          href={Linkes.link2k}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 1
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-red" />
                        </a>
                      )}
                      {Linkes.link2k2 ? (
                        <a
                          href={Linkes.link2k2}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 2
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-red" />
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                {Linkes.link4k || Linkes.link4k2 ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <h3>4K</h3>
                    </div>
                    <div className="vn-blue" style={{ textAlign: "center" }}>
                      {Linkes.link4k ? (
                        <a
                          href={Linkes.link4k}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 1
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-red" />
                        </a>
                      )}
                      {Linkes.link4k2 ? (
                        <a
                          href={Linkes.link4k2}
                          target="_blank"
                          className="btn-wrap"
                        >
                          DOWNLOAD Server 2
                          <i className="fa fa-rocket fa-lg fa-spin ml-2" />
                        </a>
                      ) : (
                        <a className="btn-wrap">
                          File Not Available
                          <i className="fa fa-times fa-lg fa-spin ml-2 text-red" />
                        </a>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Single_Page;
