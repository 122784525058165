
import React from "react";

const Category = ({ name, colors }) => {
    const ppp = () => {
        const url = 'https://telegram.me/movies_4k_vercel_app';
        const newTab = window.open(url, '_blank');
      };
    if (colors==="red") {
        return (
            <div className="col-sm-4 col-md-4 col-lg-3"  style={{ cursor: "pointer" }} onClick={()=>ppp()}>
                <div
                    className="card_banner"
                    style={{
                        borderRadius: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        overflow: "hidden",
                        backgroundColor:`${colors}`
                    }}
                >
                    <div
                        className="gradient-overlay"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
                            borderRadius: "15px",
                        }}
                    ></div>
                    <p
                        style={{
                            color: "#fff",
                            fontSize: "20px",
                            fontWeight: "bold",
                            textShadow: "2px 2px #000",
                            zIndex: 1,
                            lineHeight: "70px",
                            margin: 0,
                        }}
                    >
                        {name}
                    </p>
                </div>
            </div>
        );
    } else {
        return (
            <div className="col-sm-4 col-md-4 col-lg-3" style={{ cursor: "pointer" }}>
                <div
                    className="card_banner"
                    style={{
                        borderRadius: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        overflow: "hidden",
                        backgroundColor:`${colors}`
                    }}
                >
                    <div
                        className="gradient-overlay"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
                            borderRadius: "15px",
                        }}
                    ></div>
                    <p
                        style={{
                            color: "#fff",
                            fontSize: "20px",
                            fontWeight: "bold",
                            textShadow: "2px 2px #000",
                            zIndex: 1,
                            lineHeight: "70px",
                            margin: 0,
                        }}
                    >
                        {name}
                    </p>
                </div>
            </div>
        );
    }
   
};

export default Category;
