import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function Mo_Edit() {
  const { id } = useParams();

  // get Genar
  const [cookies] = useCookies(["jwt"]);
  const [Genar, setGenar] = useState([]);
  const Message = (M) => toast.success(M, { autoClose: 1000 });

  // language
  const [language, Setlanguage] = useState([]);
  //year
  const [year, Setyear] = useState([]);
  // country

  const [country, Setcountry] = useState([]);

  // useEffcet
  useEffect(() => {
    axios
      .get("https://darkweb4k.vercel.app/api/admin/c_category", {
        headers: cookies,
      })
      .then(function (response) {
        setGenar(response.data);
      })
      .catch(function (error) {});
    axios
      .get("https://darkweb4k.vercel.app/api/admin/language", {
        headers: cookies,
      })
      .then(function (response) {
        Setlanguage(response.data);
      })
      .catch(function (error) {});

    axios
      .get("https://darkweb4k.vercel.app/api/admin/year", {
        headers: cookies,
      })
      .then(function (response) {
        Setyear(response.data);
      })
      .catch(function (error) {});
    axios
      .get("https://darkweb4k.vercel.app/api/admin/country", {
        headers: cookies,
      })
      .then(function (response) {
        Setcountry(response.data);
      })
      .catch(function (error) {});
  }, []);

  const redirect = useNavigate();
  const [Disabled, Setdisabled] = useState(0);
  const [Data, SetCategory] = useState({
    taitle: "",
    fname: "",
    released: "",
    size: "",
    quality: "",
    runtime: "",
    link5k: "",
    link4k: "",
    link2k: "",
    link1080p: "",
    link720p: "",
    link480p: "",
    poster: "",
    seenshot1: "",
    seenshot2: "",
    seenshot3: "",
    genre: "",
    language: "",
    year: "",
    country: "",
    coming_on: "",
    status: "",
    _id: "",
    tital5k: "",
    tital4k: "",
    tital2k: "",
    tital1080p: "",
    tital720p: "",
    tital480p: "",
  });
  const OnInputs = (e) => {
    SetCategory({ ...Data, [e.target.name]: e.target.value });
  };

  const Submited = (e) => {
    e.preventDefault();
    Setdisabled(1);
    axios
      .put("https://darkweb4k.vercel.app/api/admin/Addmovies", {
        Headers: cookies,
        data: Data,
      })
      .then(function (response) {
        Setdisabled(0);
        Message(response.data.updated);
        redirect("/admin/add_movies/index");
      })
      .catch(function (error) {
        Setdisabled(0);
      });
  };

  //   useEffect
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    axios
      .get(`https://darkweb4k.vercel.app/api/admin/Addmovies/${id}`, {
        headers: cookies,
      })
      .then(function (response) {
        console.log(response.data.show1);
        SetCategory(response.data.show1);
      })
      .catch(function (error) {});
  }, []);
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header"></div>
        <section className="content pb-3">
          <div className="container-fluid">
            {/* /.card */}
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="m-0">Add Movies</h3>
                <Link
                  to="/admin/add_movies/index"
                  className="btn btn-success ml-auto"
                >
                  View Movies
                </Link>
              </div>
              {/* /.card-header */}

              <div className="card-body">
                <form
                  onSubmit={(e) => Submited(e)}
                  method="post"
                  encType="multipart/form-data"
                >
                  <div className="row">
                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">Taitle</label>
                      <input
                        type="text"
                        name="taitle"
                        value={Data.taitle}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">Full Name</label>
                      <input
                        type="text"
                        name="fname"
                        value={Data.fname}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <input
                      type="hidden"
                      name="_id"
                      value={Data._id}
                      onChange={(e) => OnInputs(e)}
                      className="form-control"
                    />

                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">Released</label>
                      <input
                        type="date"
                        name="released"
                        value={Data.released}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Genre</label>
                      <select
                        name="genre"
                        value={Data.genre}
                        className="form-select form-select-lg mb-3 form-control"
                        onChange={(e) => OnInputs(e)}
                      >
                        <option>Open this select menu</option>
                        {Genar.map((iteam, index) => (
                          <option key={index + 1} value={iteam.category}>
                            {iteam.category}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Language</label>
                      <select
                        className="form-select form-select-lg mb-3 form-control"
                        name="language"
                        value={Data.language}
                        onChange={(e) => OnInputs(e)}
                      >
                        <option>Open this select menu</option>
                        {language.map((iteam, index) => (
                          <option key={index + 1} value={iteam.language}>
                            {iteam.language}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Year</label>
                      <select
                        className="form-select form-select-lg mb-3 form-control"
                        name="year"
                        value={Data.year}
                        onChange={(e) => OnInputs(e)}
                      >
                        <option>Open this select menu</option>
                        {year.map((iteam, index) => (
                          <option key={index + 1} value={iteam.year}>
                            {iteam.year}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Country</label>
                      <select
                        className="form-select form-select-lg mb-3 form-control"
                        name="country"
                        value={Data.country}
                        onChange={(e) => OnInputs(e)}
                      >
                        <option>Open this select menu</option>
                        {country.map((iteam, index) => (
                          <option key={index + 1} value={iteam.country}>
                            {iteam.country}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">Size</label>
                      <input
                        type="text"
                        name="size"
                        value={Data.size}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">Quality</label>
                      <input
                        type="text"
                        name="quality"
                        value={Data.quality}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">RunTime</label>
                      <input
                        type="text"
                        name="runtime"
                        value={Data.runtime}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">
                        Movie Poster Link
                      </label>
                      <input
                        type="text"
                        name="poster"
                        placeholder="http//......"
                        value={Data.poster}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">
                        ScreenShots 1 Link
                      </label>
                      <input
                        type="text"
                        name="seenshot1"
                        placeholder="http//......"
                        value={Data.seenshot1}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">
                        ScreenShots 2 Link
                      </label>
                      <input
                        type="text"
                        name="seenshot2"
                        placeholder="http//......"
                        value={Data.seenshot2}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="text-danger"
                      >
                        Short Link
                      </label>
                      <input
                        type="text"
                        name="seenshot3"
                        placeholder="http//......"
                        value={Data.seenshot3}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="text-success"
                      >
                        Download Link 480p
                      </label>
                      <input
                        type="text"
                        name="tital480p"
                        placeholder="Enter tital"
                        value={Data.tital480p}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                      <input
                        type="text"
                        name="link480p"
                        placeholder="http//......"
                        value={Data.link480p}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="text-success"
                      >
                        Download Link 720p
                      </label>
                      <input
                        type="text"
                        name="tital720p"
                        placeholder="Enter tital"
                        value={Data.tital720p}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                      <input
                        type="text"
                        name="link720p"
                        placeholder="http//......"
                        value={Data.link720p}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="text-success"
                      >
                        Download Link 1080p
                      </label>
                      <input
                        type="text"
                        name="tital1080p"
                        placeholder="Enter tital"
                        value={Data.tital1080p}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                      <input
                        type="text"
                        name="link1080p"
                        placeholder="http//......"
                        value={Data.link1080p}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="text-success"
                      >
                        Download Link 2k
                      </label>
                      <input
                        type="text"
                        name="tital2k"
                        placeholder="Enter tital"
                        value={Data.tital2k}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                      <input
                        type="text"
                        name="link2k"
                        placeholder="http//......"
                        value={Data.link2k}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="text-success"
                      >
                        Download Link 4k
                      </label>
                      <input
                        type="text"
                        name="tital4k"
                        placeholder="Enter tital"
                        value={Data.tital4k}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                      <input
                        type="text"
                        name="link4k"
                        placeholder="http//......"
                        value={Data.link4k}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="text-success"
                      >
                        Download Link 5k
                      </label>
                      <input
                        type="text"
                        name="tital5k"
                        placeholder="Enter tital"
                        value={Data.tital5k}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                      <input
                        type="text"
                        name="link5k"
                        placeholder="http//......"
                        value={Data.link5k}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>
                    <div className="form-group col-lg-6">
                      <label htmlFor="exampleInputEmail1">Trending Now</label>
                      <select
                        name="coming_on"
                        className="form-select form-select-lg mb-3 form-control"
                        onChange={(e) => OnInputs(e)}
                        value={Data.coming_on}
                      >
                        <option>Open this select menu</option>
                        <option value="0">Not</option>
                        <option value="1">Trending</option>
                      </select>
                    </div>

                    <div className="form-group col-lg-6">
                      <label>Comeing Soon</label>
                      <select
                        name="status"
                        value={Data.status}
                        className="form-select form-select-lg mb-3 form-control"
                        onChange={(e) => OnInputs(e)}
                      >
                        <option>Open this select menu</option>
                        <option value="1">Now</option>
                        <option value="0">Coming Soon</option>
                      </select>
                    </div>

                    <div className="col-lg-12 d-flex justify-content-center">
                      <button className="btn btn-success" disabled={Disabled}>
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}

export default Mo_Edit;
