import React from "react";

function Video() {
  return (
    <>
      <div style={{ height: "50%" }} className="bg-info">
        <h1 style={{ marginTop: "80px" }} className="text-center">
          How To Download || <a href="https://telegram.me/movies_4k_vercel_app" target="_blank" className="btn btn-success"> join my telegram channel</a>
        </h1>
        <div className="d-flex justify-content-center mb-3">
          <img
            src="https://i.postimg.cc/bvT9tjGM/imgpsh-fullsize-anim.jpg"
            alt="1234567"
            width="30%"
            className="m-2"
          />
        </div>
      </div>
    </>
  );
}

export default Video;
