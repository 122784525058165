import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Movie from "../components/Movie";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";

const CategoryWiseMovies = () => {
  const [Loading, SetLoading] = useState(false);
  const [movies, SetMovies] = useState([]);
  const [NoMore, SetNoMore] = useState(true);
  const [Pages, SetPages] = useState(2);
  const { category } = useParams();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    SetLoading(true);
    axios
      .get(`https://darkweb4k.vercel.app/api/websites/movies/${category}`)
      .then(function (response) {
        SetLoading(false);
        SetMovies(response.data.movies);
      })
      .catch(function (error) {
        console.log(error);
        SetLoading(false);
      });
  }, []);

  // infinity
  const fetchData = () => {
    axios
      .get(
        `https://darkweb4k.vercel.app/api/websites/movies/${category}?page=${Pages}&limit=12`
      )
      .then(function (response) {
        SetMovies([...movies, ...response.data.movies]);
        SetPages(Pages + 1);
        if (response.data.movies.length === 0) {
          SetNoMore(false);
        }
      })
      .catch(function (error) {
        SetNoMore(false);
      });
  };

  return (
    <div className="container pppppp" style={{ backgroundColor: "black" }}>
      <h1 className="text-center display-4 my-4 font-weight-bold">
        {category}
      </h1>
      <InfiniteScroll
        dataLength={movies.length} //This is important field to render the next data
        next={fetchData}
        hasMore={NoMore}
        loader={<h4 className="text-center">Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b className="text-danger">Yay! You have seen it all</b>
          </p>
        }
      >
        <ul className="movies-list">
          {Loading === false ? (
            <>
              {movies.map((item, index) => {
                return (
                  <li key={index + 1}>
                    <Movie
                      id={item._id}
                      poster={item.poster}
                      title={item.fname}
                    />
                  </li>
                );
              })}
            </>
          ) : (
            <>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <div key={index}>
                  <Skeleton height={400} width={"100%"} />
                </div>
              ))}
            </>
          )}
        </ul>
      </InfiniteScroll>
    </div>
  );
};

export default CategoryWiseMovies;
