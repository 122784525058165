import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import Movie from './Movie';
import Cardsub from '../Website/Cardsub';

const MovieSlide = ({ categoryTitle, moviesArray, loading, searchKeyword }) => {
    const navigate = useNavigate();
    const filter = (categoryName) => {
        navigate(`/movies/category/${categoryName}`)
    };
    return (
        <div className="container">
            <div className="d-flex align-item-center justify-content-between">
                <p className="font-weight-bold heading">{categoryTitle}</p>
                <p className="mt-2 sm-mt-3 btn btn-info btn-sm" style={{ cursor: "pointer" }} onClick={(e) => filter(searchKeyword)}>View All</p>
            </div>
            <Swiper
                slidesPerView={2}
                spaceBetween={0}
                breakpoints={{
                    360: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    540: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 10,
                    },
                    990: {
                        slidesPerView: 4,
                        spaceBetween: 10,
                    },
                    1200: {
                        slidesPerView: 5,
                        spaceBetween: 10,
                    },
                }
                }
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                pagination={true} modules={[Pagination,Autoplay]} className="mySwiper" navigation
            >
                {loading === false ? (
                    moviesArray.map((item, index) => (
                        <SwiperSlide key={index + 1}>
                            <Movie id={item._id} poster={item.poster} title={item.fname} />
                        </SwiperSlide>
                    ))
                ) : (
                    <>
                        <Cardsub />
                    </>
                )}
            </Swiper>
        </div>
    )
}

export default MovieSlide
