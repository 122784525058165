import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

const Header = () => {
  const [progress, setProgress] = useState(0);
  const listen = useNavigate();
  useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 400);
  }, [listen]);
  return (
    <>
      <LoadingBar
        color="red"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark fixed-top p-0">
        <div className="ppp">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <Link
                  to="/"
                  className="animate-charcter pt-3"
                  style={{ fontSize: "28px" }}
                >
                 Movies4k
                </Link>
              </div>
            </div>
          </div>
        </div>

        <button
          className="navbar-toggler float-end mt-2 mx-2"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavDropdown"
        >
          <ul className="navbar-nav text-center">
            <li className="nav-item">
              <b>
                <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
              </b>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Header;
