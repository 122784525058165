import React from "react";
import Master from "./Admin/Master";
import Index2 from "./Website/Index2";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Admin/Auth/Login";
import Singal from "./Website/Singal";
import Protected from "./Protected";
import M_index from "./Admin/Category/M_index";
import M_create from "./Admin/Category/M_create";
import M_edit from "./Admin/Category/M_edit";
import Lng_Index from "./Admin/Language/Lng_Index";
import Lng_Create from "./Admin/Language/Lng_Create";
import Lng_Edit from "./Admin/Language/Lng_Edit";
import Y_Index from "./Admin/Year/Y_Index";
import Y_Create from "./Admin/Year/Y_Create";
import Y_Edit from "./Admin/Year/Y_edit";
import Co_Index from "./Admin/Country/Co_Index";
import Co_Create from "./Admin/Country/Co_Create";
import Co_Edit from "./Admin/Country/Co_Edit";
import Mo_Index from "./Admin/MoviesAdd/Mo_index";
import Mo_Create from "./Admin/MoviesAdd/Mo_Create";
import Mo_Edit from "./Admin/MoviesAdd/Mo_Edit";
import Proteced from "./Website/Proteced";
import { SkeletonTheme } from "react-loading-skeleton";
import Privacy_Policy from "./Website/Privacy_Policy";
import Disclaimer from "./Website/Disclaimer";
import Single_Page from "./Website/Single_Page";
import Page404 from "./Website/Page404";
import Video from "./Website/Video";
import Ac_Index from "./Admin/Active/Ac_Index";
import Ac_Edit from "./Admin/Active/Ac_Edit";
import CategoryWiseMovies from "./Website/CategoryWiseMovies";

function App() {
  const docTitle = document.title;
  window.addEventListener("blur", () => {
    document.title = "Come Back Please !!";
  });
  window.addEventListener("focus", () => {
    document.title = docTitle;
  });
  return (
    <>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Proteced Component={Index2} />}></Route>
            <Route
              path="/download/single_page/:id"
              element={<Single_Page />}
            ></Route>
            <Route
              path="/privacy"
              element={<Proteced Component={Privacy_Policy} />}
            ></Route>
            <Route
              path="/disclaimer"
              element={<Proteced Component={Disclaimer} />}
            ></Route>
            <Route
              path="/singal/63a4834c4d96381bc360ba29/download/63a3244e0b27e4a921573a00/63a4834c4d96381bc360ba29/:id"
              element={<Proteced Component={Singal} />}
            ></Route>
             <Route
              path="/movies/category/:category"
              element={<Proteced Component={CategoryWiseMovies} />}
            ></Route>
            <Route
              path="/video"
              element={<Proteced Component={Video} />}
            ></Route>
            <Route path="*" element={<Page404 />}></Route>
            {/* &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& Admin &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&& */}
            {/* Admin route */}
            <Route path="/login" element={<Login />}></Route>
            <Route path="/admin" element={<Protected Cmp={Master} />}></Route>

            {/* Category Add */}
            <Route
              path="/admin/add/movies"
              element={<Protected Cmp={M_index} />}
            ></Route>
            <Route
              path="/admin/category/create"
              element={<Protected Cmp={M_create} />}
            ></Route>
            <Route
              path="/admin/category/edit/:id"
              element={<Protected Cmp={M_edit} />}
            ></Route>

            {/* Language */}
            <Route
              path="/admin/Language/index"
              element={<Protected Cmp={Lng_Index} />}
            ></Route>
            <Route
              path="/admin/Language/create"
              element={<Protected Cmp={Lng_Create} />}
            ></Route>
            <Route
              path="/admin/Language/edit/:id"
              element={<Protected Cmp={Lng_Edit} />}
            ></Route>

            {/* Year */}
            <Route
              path="/admin/Year/index"
              element={<Protected Cmp={Y_Index} />}
            ></Route>
            <Route
              path="/admin/Year/create"
              element={<Protected Cmp={Y_Create} />}
            ></Route>
            <Route
              path="/admin/Year/edit/:id"
              element={<Protected Cmp={Y_Edit} />}
            ></Route>

            {/* Country */}
            <Route
              path="/admin/Country/index"
              element={<Protected Cmp={Co_Index} />}
            ></Route>
            <Route
              path="/admin/Country/create"
              element={<Protected Cmp={Co_Create} />}
            ></Route>
            <Route
              path="/admin/Country/edit/:id"
              element={<Protected Cmp={Co_Edit} />}
            ></Route>
            {/* movies ads none */}
            <Route
              path="/admin/active/index"
              element={<Protected Cmp={Ac_Index} />}
            ></Route>
            <Route
              path="/admin/active/edit/:id"
              element={<Protected Cmp={Ac_Edit} />}
            ></Route>
            {/* Add Movies */}
            <Route
              path="/admin/add_movies/index"
              element={<Protected Cmp={Mo_Index} />}
            ></Route>
            <Route
              path="/admin/add_movies/create"
              element={<Protected Cmp={Mo_Create} />}
            ></Route>
            <Route
              path="/admin/add_movies/edit/:id"
              element={<Protected Cmp={Mo_Edit} />}
            ></Route>
          </Routes>
        </BrowserRouter>
      </SkeletonTheme>
    </>
  );
}

export default App;
