import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactPaginate from "react-paginate";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

function Mo_Index() {
  const [Load, SetLoad] = useState([]);
  const [cookies] = useCookies(["jwt"]);
  const [Disabled, Setdisabled] = useState(0);
  const [peginateCount, SetCount] = useState(0);
  const Message = (M) => toast.error(M, { autoClose: 1000 });

  const LoadCategory = (peginate) => {
    axios
      .get(
        `https://darkweb4k.vercel.app/api/admin/Addmovies/indexAdmin?page=${peginate}&limit=5`,
        {
          headers: cookies,
        }
      )
      .then(function (response) {
        SetLoad(response.data.reacored);
        SetCount(Math.ceil(response.data.count / 5));
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    LoadCategory(1);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const Deleted = (id) => {
    Setdisabled(1);
    axios
      .delete(`https://darkweb4k.vercel.app/api/admin/Addmovies/${id}`, {
        headers: cookies,
      })
      .then(function (response) {
        LoadCategory();
        Setdisabled(0);
        Message(response.data.message);
      })
      .catch(function (error) {});
  };

  // ##################################### pagination ###########################################
  const handlePageClick = (data) => {
    const peginate = data.selected + 1 ? data.selected + 1 : 1;
    LoadCategory(peginate);
  };
  // search Engin
  const searchEngin = (e) => {
    if (e.target.value === "") {
      LoadCategory(1);
    } else {
      axios
        .post("https://darkweb4k.vercel.app/api/admin/Addmovies/search", {
          Headers: cookies,
          search: e.target.value,
        })
        .then(function (response) {
          SetLoad(response.data);
        })
        .catch(function (error) {});
    }
  };
  // ##################################### End pagination ###########################################

  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="content-wrapper">
        <div className="content-header"></div>
        <section className="content pb-3">
          <div className="container-fluid">
            {/* /.card */}
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="m-0">Movies</h3>
                <Link
                  to="/admin/add_movies/create"
                  className="btn btn-success ml-auto"
                >
                  <i className="nav-icon far fa-plus-square p-2 text-primary"></i>
                  Add Movies
                </Link>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="table-responsive">
                  <div className="d-flex justify-content-center mb-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      aria-label="Search"
                      style={{ width: "50%" }}
                      onChange={(e) => searchEngin(e)}
                    />
                  </div>

                  <table className="table table-bordered table-striped text-center">
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>Poster</th>
                        <th style={{ width: "600px" }}>Taitle</th>
                        <th style={{ width: "300px" }}>Short Link</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Load.map((iteam, index) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>
                            <img
                              src={iteam.poster === "" ? "" : iteam.poster}
                              alt="img"
                              style={{ height: "70px", width: "70%" }}
                            />
                          </td>
                          <td>{iteam.taitle}</td>
                          <td>
                            https://techbigs.vercel.app/
                            {iteam._id}
                          </td>
                          <td>
                            <div className="d-flex">
                              <Link
                                to={`/admin/add_movies/edit/${iteam._id}`}
                                className="btn btn-success btn-sm m-1"
                              >
                                <i className="nav-icon fas fa-edit" />
                              </Link>
                              <Link
                                to
                                className="btn btn-danger btn-sm m-1"
                                onClick={() => Deleted(iteam._id)}
                                disabled={Disabled}
                              >
                                <i className="nav-icon fas fa-trash" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <ReactPaginate
                    previousLabel="<<"
                    nextLabel=">>"
                    breakLabel="..."
                    pageCount={
                      Number(peginateCount) ? Number(peginateCount) : 1
                    }
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination justify-content-center"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextClassName={"page-item"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}

export default Mo_Index;
