import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./login.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCookies } from "react-cookie";
import { useEffect } from "react";

const Login = () => {
  const notify = (m) =>
    toast.error(m, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  const loginss = (m) => toast.success(m);

  const redireact = useNavigate();
  const [cookies, setCookie] = useCookies(["jwt"]);
  const [btnDiseble, setDisebale] = useState(0);

  const [login, setLogin] = useState({
    email: "",
    pass: "",
  });

  const SetLoguser = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const sessIon_start = (e) => {
    e.preventDefault();
    axios
      .post("https://darkweb4k.vercel.app/api/admin/login", login)
      .then(function (response) {
        if (response.data.user) {
          setCookie("jwt", response.data.token);
          setDisebale(0);
          return loginss(response.data.message);
        }
        notify(response.data.message);
        setDisebale(0);
      })
      .catch(function (error) {
        console.log(error);
      });
    setDisebale(1);
  };

  useEffect(() => {
    if (cookies.jwt) {
      redireact("/admin");
    }
  });
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        theme="dark"
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="hold-transition login-page bg-dark"
        style={{
          backgroundImage: `url('https://png.pngtree.com/background/20211215/original/pngtree-binary-matrix-code-flow-dark-abstract-background-picture-image_1466835.jpg')`,
        }}
      >
        <div className="login-box">
          <h1>
            <Link to="/" className="text-center text-success">
              Back Website
            </Link>
          </h1>
          {/* /.login-logo */}
          {/* {cookies.jwt} */}
          <div className="card" id="myDIV">
            <div
              className="card-body login-card-body"
              style={{
                backgroundImage: `url('https://png.pngtree.com/background/20211215/original/pngtree-binary-matrix-code-flow-dark-abstract-background-picture-image_1466835.jpg')`,
              }}
            >
              <div className="login-logo bg-dark p-3">
                <h1 className="text-primary">
                  <b style={{ color: "red" }}>Movies</b>4K
                </h1>
              </div>
              <form onSubmit={(e) => sessIon_start(e)}>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={setLogin.email}
                    placeholder="Email"
                    onChange={(e) => SetLoguser(e)}
                    required
                  />
                  <div className="input-group-append">
                    <div className="input-group-text bg-info">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="pass"
                    value={setLogin.pass}
                    onChange={(e) => SetLoguser(e)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text bg-info">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* <div className="col-8">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label htmlFor="remember" className='text-white'>
                                                Remember Me
                                            </label>
                                        </div>
                                    </div> */}
                  {/* /.col */}
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={btnDiseble}
                    >
                      Sign In
                    </button>
                  </div>
                  {/* /.col */}
                </div>
              </form>
              {/* /.social-auth-links */}

              <p className="mb-0">
                <Link to="/login" className="text-center">
                  Register a new membership
                </Link>
              </p>
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
