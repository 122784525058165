import React from "react";
import "admin-lte/plugins/fontawesome-free/css/all.min.css";
import "admin-lte/plugins/overlayScrollbars/css/OverlayScrollbars.min.css";
import "admin-lte/dist/css/adminlte.min.css";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SideBar from "./Admin/Layouts/SideBar";
import Footer from "./Admin/Layouts/Footer";
import Scripts from "./Scripts";

const Protected = (props) => {
  let Cmp = props.Cmp;

  const [cookies] = useCookies(["jwt"]);
  const redireact = useNavigate();

  useEffect(() => {
    if (!cookies.jwt) {
      redireact("/login");
    }
  });
  return (
    <>
      <div className="wrapper">
        <SideBar />
        <Cmp />
        <Footer />
      </div>
      <Scripts />
    </>
  );
};

export default Protected;
