import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function Ac_Index() {
  const [cookies] = useCookies(["jwt"]);
  const [Load, SetLoad] = useState([]);

  useEffect(() => {
    axios
      .get("https://darkweb4k.vercel.app/api/admin/active", {
        headers: cookies,
      })
      .then(function (response) {
        SetLoad(response.data);
      })
      .catch(function (error) {});

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <ToastContainer></ToastContainer>
      <div className="content-wrapper">
        <div className="content-header"></div>
        <section className="content pb-3">
          <div className="container-fluid">
            {/* /.card */}
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="m-0">Active</h3>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped text-center">
                    <thead>
                      <tr>
                        <th>Sr.No</th>
                        <th>Active</th>
                        <th style={{ width: "120px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Load.map((iteam, index) => (
                        <tr key={index + 1}>
                          <td>{index + 1}</td>
                          <td>{iteam.actives}</td>
                          <td>
                            <div className="d-flex">
                              <Link
                                to={`/admin/active/edit/${iteam._id}`}
                                className="btn btn-success btn-sm m-1"
                              >
                                <i className="nav-icon fas fa-edit" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}

export default Ac_Index;
