import React from 'react'
import { Link } from 'react-router-dom'
import '../Website/assets/css/movie.css';

const Movie = ({ id, poster, title }) => {
    return (
        <div className="movie-card mb-5">
            <Link
                to={`/singal/63a4834c4d96381bc360ba29/download/63a3244e0b27e4a921573a00/63a4834c4d96381bc360ba29/${id}`}
            >
                <div className="card-banner">
                    <div className="banner-overlay"></div>
                    <img src={poster} alt={`${title} movie poster`} />
                    <div className="banner-title">{title.substring(0, 45)}...</div>
                </div>
            </Link>
        </div>
    )
}

export default Movie
