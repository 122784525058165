import React from 'react';

function Footer() {
    return (
        <>
            {/* /.content-wrapper */}
            <footer className="main-footer d-flex justify-content-center">
                <strong>Copyright © 2014-2021  </strong>
                All rights reserved.

                <b> Version </b> 3.2.0

            </footer>
            {/* Control Sidebar */}
            <aside className="control-sidebar control-sidebar-dark">
                {/* Control sidebar content goes here */}
            </aside>
            {/* /.control-sidebar */}
        </>
    );
}

export default Footer;