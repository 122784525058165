import React, { useEffect } from "react";
import "./assets/css/index2.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import "swiper/css/pagination";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import Category from "../components/Category";
import MovieSlide from "../components/MovieSlide";
import Movie from "../components/Movie";
import InfiniteScroll from "react-infinite-scroll-component";
import Skeleton from "react-loading-skeleton";

const Index2 = () => {
  const [tvShow, setTvShow] = useState([]);
  const [Loading, SetLoading] = useState(false);

  const LoadMovies = () => {
    SetLoading(true);
    axios
      .get(`https://darkweb4k.vercel.app/api/websites/category/all`)
      .then(function (response) {
        setTvShow(response.data.TV_Show);
        SetLoading(false);
      })
      .catch(function (error) {
        SetLoading(false);
      });
  };

  const [categories] = useState([
    { id: 4, name: "Bollywood", searchKeyword: "Bollywood", color: "blue" },
    { id: 5, name: "Hollywood", searchKeyword: "Hollywood", color: "#5432a8" },
    { id: 11, name: "Marathi", searchKeyword: "Marathi", color: "orange" },
    { id: 1, name: "Action", searchKeyword: "Action", color: "#7d294b" },
    { id: 9, name: "Love", searchKeyword: "Love Story", color: "#af3cb5" },
    {
      id: 8,
      name: "South Movies",
      searchKeyword: "South Indian",
      color: "#7a5f2c",
    },
    { id: 3, name: "Horror", searchKeyword: "Horror", color: "#586e25" },
    { id: 6, name: "Adventure", searchKeyword: "Adventure", color: "#47ad45" },
    { id: 7, name: "Web Series", searchKeyword: "WebSeries", color: "#459cad" },
    { id: 10, name: "Comedy", searchKeyword: "Comedy", color: "green" },
  ]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    LoadMovies();
    fetchData();
  }, []);
  // ##################################### Fillters ###########################################

  // search Engin
  const [Search, setSearch] = useState([]);
  const [Condition, SetCondition] = useState(false);
  const searchEngin = (e) => {
    if (e.target.value !== "") {
      SetCondition(true);
      axios
        .post("https://darkweb4k.vercel.app/api/websites/search", {
          search: e.target.value,
        })
        .then(function (response) {
          setSearch(response.data);
        })
        .catch(function (error) {});
    } else {
      SetCondition(false);
      LoadMovies();
    }
  };

  const navigate = useNavigate();
  const filter = (categoryName) => {
    navigate(`/movies/category/${categoryName}`);
  };

  const [Pages, SetPages] = useState(1);
  const [NoMore, SetNoMore] = useState(true);
  const [movies, SetMovies] = useState([]);
  const fetchData = () => {
    axios
      .get(
        `https://darkweb4k.vercel.app/api/websites/all?page=${Pages}&limit=12`
      )
      .then(function (response) {
        SetMovies([...movies, ...response.data.reacored]);
        SetPages(Pages + 1);
        if (response.data.reacored.length === 0) {
          SetNoMore(false);
        }
      })
      .catch(function (error) {
        SetNoMore(false);
      });
  };
  const ppp = () => {
    const url = "https://anime4k.free.nf/";
    const newTab = window.open(url, "_blank");
  };
  return (
    <>
      <span id="top">
        <main>
          <section className="upcoming">
            <div className="container-fluid">
              <div className="title-wrapper">
                <input
                  className="form-control py-4 mx-auto mb-4"
                  type="text"
                  style={{ width: "70%" }}
                  placeholder="Search your favourite movie !"
                  onChange={(e) => searchEngin(e)}
                />
              </div>
              {Condition ? (
                <div className="container">
                  <div className="movies-list">
                    {Search.map((item, index) => (
                      <SwiperSlide key={index + 1}>
                        <Movie
                          id={item._id}
                          poster={item.poster}
                          title={item.fname}
                        />
                      </SwiperSlide>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <section style={{ margin: "0px !important" }}>
                    <div className="container">
                      <div className="flex-wrapper">
                        <ul
                          className="filter-list mt-2"
                          style={{ marginBottom: "0" }}
                        >
                          {categories.map((category, index) => (
                            <li key={index}>
                              <div
                                onClick={() => filter(category.searchKeyword)}
                                className="mb-3"
                              >
                                <Category
                                  name={category.name}
                                  colors={category.color}
                                />
                              </div>
                            </li>
                          ))}
                          <li>
                            <div className="mb-3">
                              <Category name={"Telegram"} colors="red" />
                            </div>
                          </li>
                          <li>
                            <div className="mb-3">
                              <div
                                className="col-sm-4 col-md-4 col-lg-3"
                                style={{ cursor: "pointer" }}
                                onClick={() => ppp()}
                              >
                                <div
                                  className="card_banner"
                                  style={{
                                    borderRadius: "15px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                    overflow: "hidden",
                                    backgroundColor: `yellow`,
                                  }}
                                >
                                  <div
                                    className="gradient-overlay"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                      background:
                                        "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%)",
                                      borderRadius: "15px",
                                    }}
                                  ></div>
                                  <p
                                    style={{
                                      color: "#fff",
                                      fontSize: "20px",
                                      fontWeight: "bold",
                                      textShadow: "2px 2px #000",
                                      zIndex: 1,
                                      lineHeight: "70px",
                                      margin: 0,
                                    }}
                                  >
                                    Anime4k
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </section>
                  <MovieSlide
                    categoryTitle={"Trending Movies"}
                    loading={Loading}
                    moviesArray={tvShow}
                    searchKeyword={"Trending Movies"}
                  />

                  <div className="container">
                    <div className="d-flex align-item-center justify-content-between">
                      <p className="font-weight-bold heading">All Movies</p>
                      <p className="mt-2 sm-mt-3 btn btn-info btn-sm d-none"></p>
                    </div>
                    <InfiniteScroll
                      dataLength={movies.length} //This is important field to render the next data
                      next={fetchData}
                      hasMore={NoMore}
                      loader={<h4 className="text-center">Loading...</h4>}
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b className="text-danger">
                            Yay! You have seen it all
                          </b>
                        </p>
                      }
                    >
                      <ul className="movies-list">
                        {Loading === false ? (
                          <>
                            {movies.map((item, index) => {
                              return (
                                <li key={index + 1}>
                                  <Movie
                                    id={item._id}
                                    poster={item.poster}
                                    title={item.fname}
                                  />
                                </li>
                              );
                            })}
                          </>
                        ) : (
                          <>
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                              <div key={index}>
                                <Skeleton height={400} width={"100%"} />
                              </div>
                            ))}
                          </>
                        )}
                      </ul>
                    </InfiniteScroll>
                  </div>
                </>
              )}
            </div>
          </section>
        </main>
      </span>
    </>
  );
};
export default Index2;
