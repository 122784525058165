import axios from "axios";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CardSkeletonSingle from "./CardSkeletonSingle";
import { SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";

const Singal = () => {
  const { id } = useParams();
  const [Loading, SetLoading] = useState(false);
  const [movies, setMovies] = useState([]);
  const [others, SetOther] = useState([]);
  const [UseEffcs, setUseEffcs] = useState(1);
  const uRff = useRef();

  // view singale data
  const showOneRecored = () => {
    axios
      .get(`https://darkweb4k.vercel.app/api/websites/${id}`)
      .then(function (response) {
        setMovies(response.data.show1);
        // other movies
        axios
          .post("https://darkweb4k.vercel.app/api/websites/other", {
            other: response.data.show1.genre,
          })
          .then(function (res) {
            SetOther(res.data);
            SetLoading(true);
          })
          .catch(function (errors) {});
        //End other movies
      })
      .catch(function (error) {});
  };
  uRff.current = showOneRecored;
  useEffect(() => {
    uRff.current();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [UseEffcs]);

  const Othermovies = () => {
    setUseEffcs(UseEffcs + 1);
  };
  const [Activess, SetActive] = useState([]);
  const actives = () => {
    axios
      .get(`https://darkweb4k.vercel.app/api/websites/view/all/data`)
      .then(function (response) {
        SetActive(response.data[0]);
      })
      .catch(function (error) {});
  };
  useEffect(() => {
    actives();
  }, []);
  return (
    <>
      <main>
        <article>
          {Loading ? (
            <section className="movie-detail" style={{ marginTop: "-60px" }}>
              <div className="container">
                <figure className="movie-detail-banner">
                  <img src={movies.poster} alt="Free guy movie poster" />
                  <button className="play-btn2">
                    <ion-icon name="play-circle-outline" />
                  </button>
                </figure>
                <div className="movie-detail-content">
                  <h3 className="detail-title">
                    <strong>{movies.fname}</strong>
                  </h3>
                  <div className="meta-wrapper">
                    <div className="badge-wrapper">
                      <div className="badge badge-outline">HD</div>
                    </div>
                    <div className="ganre-wrapper">
                      <Link to>{movies.genre}</Link>
                    </div>
                    <div className="date-time">
                      <div>
                        <ion-icon name="calendar-outline" />
                        <time dateTime={2021}>{movies.year}</time>
                      </div>
                      <div>
                        <ion-icon name="time-outline" />
                        <time dateTime="PT115M">{movies.runtime}</time>
                      </div>
                      <div>
                        <time dateTime="PT115M">{movies.language}</time>
                      </div>
                    </div>
                  </div>
                  <p className=" lh-sm" style={{ textAlign: "justify" }}>
                    We provides one of the finest Link Protecting services in
                    the world. Our Team Experts have created our very own Link
                    Sharing Algorithm (implemented carefully) to maintain your
                    Links at Max Speed with the alternative of consuming much
                    more and inappropriate Resources. We at JioLink always care
                    for our users and protect their information and all the
                    services are ascendable referred to your benefit like you
                    can easily add more links and edit without putting a notable
                    impact on yours mind.
                  </p>
                  <div className="details-actions">
                    <button className="share">
                      <ion-icon name="share-social" />
                      <span>Share</span>
                    </button>
                    <div className="title-wrapper">
                      <Link to="/" className="btn btn-primary">
                        Back Home
                      </Link>
                    </div>
                    {/*  <button className="btn2 btn2-primary">
                    <ion-icon name="play" />
                    <span>Watch Now</span>
                  </button> */}
                  </div>
                </div>
              </div>
              <div className="container mt-3 justify-content-center">
                <div className="justify-content-center">
                  {movies.seenshot1 ? (
                    <img
                      src={movies.seenshot1}
                      className="img-thumbnail"
                      alt="thumbnail"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                  {movies.seenshot2 ? (
                    <img
                      src={movies.seenshot2}
                      className="img-thumbnail my-2"
                      alt="thumbnail"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="text-center mt-4">
                <h4 className="text-info mb-3">{movies.fname}</h4>
                <p className="text-success bg-black p-3">{movies.quality}</p>
                {/* <a
                  href={movies.seenshot3}
                  className="btn btn-success btn-lg"
                  target="_blank"
                >
                  Download Now
                </a> */}
                {Activess.actives == 1 ? (
                  <Link
                    to={`/download/single_page/${movies._id}`}
                    className="btn btn-success btn-lg"
                  >
                    Download Now
                  </Link>
                ) : (
                  <a
                    href={movies.seenshot3}
                    className="btn btn-success btn-lg"
                    target="_blank"
                  >
                    Download Now
                  </a>
                )}
                <br />

                <Link to="/video" className="btn btn-danger btn-lg mt-2">
                  How To Download
                </Link>
              </div>
            </section>
          ) : (
            <CardSkeletonSingle />
          )}
          <section className="tv-series">
            <div
              className="container"
              style={{ marginTop: "-180px", marginBottom: "-90px" }}
            >
              <div className="border mb-3"></div>
              <h1 className="h2 section-title">World Best Movies</h1>
              <ul className="movies-list" style={{ marginTop: "-30px" }}>
                {Loading
                  ? others.map((iteam, index) => (
                      <li key={index + 1}>
                        <div className="movie-card bg-black">
                          <Link
                            to={`/singal/63a4834c4d96381bc360ba29/download/63a3244e0b27e4a921573a00/63a4834c4d96381bc360ba29/${iteam._id}`}
                            onClick={Othermovies}
                          >
                            <figure className="card-banner">
                              <img
                                src={iteam.poster}
                                alt="Sonic the Hedgehog 2 movie poster"
                              />
                              <div className="banner-title">
                                {iteam.taitle.substring(0, 45)}...
                              </div>
                            </figure>
                          </Link>
                          <div className="title-wrapper px-1">
                            <Link
                              to={`/singal/63a4834c4d96381bc360ba29/download/63a3244e0b27e4a921573a00/63a4834c4d96381bc360ba29/${iteam._id}`}
                              onClick={Othermovies}
                            ></Link>
                          </div>
                        </div>
                      </li>
                    ))
                  : [1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                      <SwiperSlide key={index + 1}>
                        <Skeleton height={400} width={"100%"} />
                      </SwiperSlide>
                    ))}
              </ul>
            </div>
          </section>
        </article>
      </main>
    </>
  );
};

export default Singal;
