import React from "react";
import Iteams from "./Iteams";
import DataSide from "./data/sidebar.json";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const SideBar = () => {
  return (
    <>
      {/* Navbar */}
      <nav className="main-header navbar navbar-expand navbar-dark navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to className="nav-link" data-widget="pushmenu" role="button">
              <i className="fas fa-bars" />
            </Link>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          <li className="nav-item">
            <Link className="btn btn-danger d-flex" to="/">
              <i className="fas fa-user-secret" style={{ fontSize: "30px" }} />
            </Link>
          </li>
        </ul>
      </nav>
      {/* /.navbar */}
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <Link to="/" className="brand-link">
          {/* <img
            src={require("../../../node_modules/admin-lte/dist/img/AdminLTELogo.png")}
            alt=""
            className="brand-image img-circle elevation-3"
            style={{ opacity: ".8" }}
          /> */}
          <b className="brand-text text-danger ml-5">Movies4K.App</b>
        </Link>

        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel d-flex">
            <button data-widget="pushmenu">
              <div className="image">
                <img
                  src={require("../../Website/assets/images/Logo.png")}
                  alt=""
                  style={{ width: "100%" }}
                />
              </div>
            </button>
          </div>
          {/* SidebarSearch Form */}
          {/* <div className="form-inline">
            <div className="input-group" data-widget="sidebar-search">
              <input
                className="form-control form-control-sidebar"
                type="search"
                placeholder="Search"
                aria-label="Search"
              />
              <div className="input-group-append">
                <button className="btn btn-sidebar">
                  <i className="fas fa-search fa-fw" />
                </button>
              </div>
            </div>
          </div> */}
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <div
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {DataSide.map((item, index) => (
                <Iteams key={index} item={item} />
              ))}
            </div>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};

export default SideBar;
