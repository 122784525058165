import axios from "axios";
import React from "react";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

function Ac_Edit() {
  const { id } = useParams();
  const redirect = useNavigate();
  const Message = (M) => toast.success(M, { autoClose: 1000 });
  const [cookies] = useCookies(["jwt"]);
  const [Disabled, Setdisabled] = useState(0);
  const [Data, SetCategory] = useState({
    actives: "",
    _id: id,
  });

  const OnInputs = (e) => {
    SetCategory({ ...Data, [e.target.name]: e.target.value });
  };

  const Submited = (e) => {
    e.preventDefault();
    axios
      .put("https://darkweb4k.vercel.app/api/admin/active", {
        Headers: cookies,
        data: Data,
      })
      .then(function (response) {
        Setdisabled(0);
        Message(response.data.updated);
        redirect("/admin/active/index");
      })
      .catch(function (error) {
        console.log(error);
        Setdisabled(0);
      });
    Setdisabled(1);
  };
  return (
    <>
      <div className="content-wrapper">
        <div className="content-header"></div>
        <section className="content pb-3">
          <div className="container-fluid">
            {/* /.card */}
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="m-0">actives</h3>
                <Link
                  to="/admin/active/index"
                  className="btn btn-success ml-auto"
                >
                  View Active
                </Link>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <form onSubmit={(e) => Submited(e)}>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label htmlFor="exampleInputEmail1">Active</label>
                      <input
                        type="text"
                        name="actives"
                        value={Data.actives}
                        onChange={(e) => OnInputs(e)}
                        className="form-control"
                      />
                    </div>
                    <div className="mx-auto mt-3">
                      <button className="btn btn-success" disabled={Disabled}>
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {/* /.card-body */}
            </div>
            {/* /.card */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
    </>
  );
}

export default Ac_Edit;
